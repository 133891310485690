import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { CommonService } from '../common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
function htmlToPlainText(html: string): string {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}
@Component({
  selector: 'app-feedback-edit',
  templateUrl: './feedback-edit.component.html',
  styleUrls: ['./feedback-edit.component.css'],
})
export class FeedbackEditComponent {
  name_error: any;
  description_error: any;
  files_error: any;

  name_error_show: boolean = false;
  description_error_show: boolean = false;
  files_error_show: boolean = false;
  name: any;
  description: any = '';
  files: File[] = [];
  is_active: any;
  created_by: any;
  feedback_id: any;
  image_list: any[] = [];
  currentIndex = 0;
  showModal: boolean = false;
  success: boolean = false;
  danger: boolean = false;
  successContent: any = '';
  dangerContent: any = '';

  public Editor = ClassicEditor;
  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private route: Router, private el: ElementRef
  ) {}
  @ViewChild('fileInputFeedback') fileInput: any;
  @HostListener('input', ['$event']) onInput(event: any): void {
    const inputValue: string = event.target.value;
    if (inputValue && inputValue.startsWith(' ')) {
      event.target.value = inputValue.trimStart();
    }
  }
  ngOnInit() {
    this.commonService.login();

    $('.alert-success').hide();
    $('.alert-danger').hide();
    let storedArrayString: any = localStorage.getItem('userdata');
    let storedArray = JSON.parse(storedArrayString);
    this.feedback_id = localStorage.getItem('feedback_id');
    this.created_by = storedArray.user_id;
    CommonService.ActiveClass('nav-feedback');
    let formData = new FormData();

    formData.append('id', this.feedback_id);
    formData.append('oauth_key', CommonService.oauth_key);

    this.http
      .post(CommonService.apiUrl + '/feedback-edit', formData)
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          let responseData: any = response['data'][0];
          this.name = responseData.feedback_title;
          this.description = responseData.feedback_description;
          let is_active = responseData.is_active;
          this.image_list = responseData.images;
          if (is_active === 1) {
            this.is_active = true;
          } else {
            this.is_active = false;
          }
          if (this.image_list.length === 1) {
            $('.navigation').hide();
          }
        } else {
          $('.alert-success').hide();
          $('.alert-danger').show();
          $('.alert-danger').html(response['error_message']);
          window.scrollTo(0, 0);
        }
      });

    setTimeout(() => {
      $('#loading').hide();
    }, 2000);
  }
  onFileChange(event: any) {
    this.files_error_show = false;

    this.files = []; // Assuming files is an array property in your component
    let error_message = '';
    const fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        let filename: any = fileList[i].name;
        const fileExtension = filename.substring(filename.lastIndexOf('.') + 1);
        if (
          fileExtension === 'jpg' ||
          fileExtension === 'jpeg' ||
          fileExtension === 'png' ||
          fileExtension === 'gif' ||
          fileExtension === 'bmp'
        ) {
          this.files.push(fileList[i]);
        } else {
          error_message =
            "Please upload 'jpg', 'jpeg', 'png', 'gif', 'bmp' images";
        }
      }
    }

    if (error_message != '') {
      this.fileInput.nativeElement.value = '';
      this.files = [];
      this.files_error = error_message;
      this.files_error_show = true;
      // $('.alert-success').hide();
      // $('.alert-danger').show();
      window.scrollTo(0,0);
      return false;
    } else {
      return true;
    }

    // Now this.files contains an array of files
  }

  navigateToSubmit(): void {
    if (this.validation() === true) {
      let is_active: any;
      const formData: FormData = new FormData();
      formData.append('id', this.feedback_id);
      formData.append('name', this.name);
      formData.append('description', this.description);
      // if('jpg'|| 'jpeg'|| 'png'||'gif'|| 'bmp')
      // Append each file to the form data

      for (let i = 0; i < this.files.length; i++) {
        formData.append('images', this.files[i]);
      }
      if (this.is_active === true) {
        is_active = 1;
      } else {
        is_active = 0;
      }

      formData.append('is_active', is_active);
      formData.append('updated_by', this.created_by);
      formData.append('oauth_key', CommonService.oauth_key);

      // Assuming you have a feedback method to make the HTTP request
      this.http
        .post(CommonService.apiUrl + '/feedback-update', formData)
        .subscribe((response: any) => {
          if (response['is_successfull'] === '1') {
            $('.alert-success').show();
            $('.alert-danger').hide();
            $('.alert-success').html(response['success_message']);
            window.scrollTo(0, 0);

            setTimeout(() => {
              this.route.navigateByUrl('/feedback-list');
            }, 1000);
          } else {
            $('.alert-success').hide();
            $('.alert-danger').show();
            $('.alert-danger').html(response['error_message']);
            window.scrollTo(0, 0);
          }
        });
    } else {
      return;
    }
  }
  validation() {
    this.name_error_show = false;
    this.description_error_show = false;
    this.files_error_show = false;
    let error_message = '';
    if (this.name === null || this.name === undefined || this.name === '' || this.name == " ") {
      error_message = 'Please enter Feedback Title</br>';
      this.name_error_show = true;
      this.name_error='Please enter Feedback Title';
    }else {
      if (this.name.length < 2) {
        error_message +=
          'Feedback Title should be between 2 and 200 characters</br>';
        this.name_error_show = true;
        this.name_error = 'Feedback Title should be between 2 and 200 characters';
      }
      if (this.name.length > 200) {
        error_message +=
          'Feedback Title should be between 2 and 200 characters</br>';
        this.name_error_show = true;
        this.name_error = 'Feedback Title should be between 2 and 200 characters';
      }
    }
    if (
      this.description === null ||
      this.description === undefined ||
      this.description === ''
    ) {
      error_message += 'Please enter Feedback Description</br>';
      this.description_error_show = true;
      this.description_error='Please enter Feedback Description';
    }else {
      const plainTextDescription = htmlToPlainText(this.description);
      console.log(plainTextDescription);
      if (plainTextDescription.length < 2) {
        error_message +=
          'Feedback Description should be between 2 and 50,000 characters</br>';
        this.description_error_show = true;
        this.description_error =
          'Feedback Description should be between 2 and 50,000 characters';
      }
      if (plainTextDescription.length > 50000) {
        error_message +=
          'Feedback Title should be between 2 and 50,000 characters</br>';
        this.description_error_show = true;
        this.description_error =
          'Feedback Title should be between 2 and 50,000 characters';
      }
    }

    // if (this.files.length === 0) {
    //   error_message += 'Please upload image</br>';
    //   this.files_error_show = true;
    //   this.files_error='Please upload image';
    // }
    if (this.files.length >1) {
      error_message += 'Please upload one image only.</br>';
      this.files_error_show = true;
      this.files_error='Please upload one image only';
      this.fileInput.nativeElement.value = '';
      this.files=[];
    }
    if (error_message != '') {
   

      return false;
    } else {
      return true;
    }
  }
  previousTestimonial() {
    this.currentIndex =
      (this.currentIndex - 1 + this.image_list.length) % this.image_list.length;
  }

  nextTestimonial() {
    this.currentIndex = (this.currentIndex + 1) % this.image_list.length;
  }
  // onDelete(){
  //   $('.model').show();
  // }
  onDelete() {
    this.showModal = true;
    this.success = false;
    this.danger = false;
  }

  closeModal() {
    this.showModal = false;
  }

  confirmDelete() {
    let formData = new FormData();
    formData.append('id', this.feedback_id);
    formData.append('oauth_key', CommonService.oauth_key);
    $('#loading').show();

    this.http
      .post(CommonService.apiUrl + '/feedback-delete', formData)
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          $('#loading').hide();

          this.success = true;
          this.danger = false;
          this.successContent = response['success_message'];
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.route.navigateByUrl('/feedback-list');
          }, 1000);
        } else {
          this.success = false;
          this.danger = true;
          this.dangerContent = response['error_message'];
          $('#loading').hide();

          // $('.alert-danger').html(response['error_message']);
          window.scrollTo(0, 0);
        }
      });
  }
  onCancel() {
    this.route.navigateByUrl('/feedback-list');
  }
  onClickLogo() {
    this.route.navigateByUrl('/dashboard');
  }
}
