import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  showModal:boolean = false;
  userModal:boolean = false;
  constructor(private commonService: CommonService,private router: Router,private http: HttpClient){}
  ngOnInit() {
    this.commonService.login();
   
    let storedArrayString: any = localStorage.getItem('userdata');
    let storedArray = JSON.parse(storedArrayString);
    if(storedArray.user_role_id===1){
      this.userModal = true ;
    }
  }
  navigateToDashboard(){
    this.router.navigateByUrl('/dashboard');
  }
  navigateToFeedback(){
    this.router.navigateByUrl('/feedback-list');
  }
  navigateToServices(){
    this.router.navigateByUrl('/services-list');
  }
  navigateToFeatures(){
    this.router.navigateByUrl('/feature-list');
  }
  navigateToIndustry(){
    this.router.navigateByUrl('/industry-list');
  }
  navigateToContact(){
    this.router.navigateByUrl('/contact-list');
  }
  navigateToRequest(){
    this.router.navigateByUrl('/demo-list');
  }
  navigateToBlog(){
    this.router.navigateByUrl('/blog-list');
  }
  navigateToUser(){
    this.router.navigateByUrl('/user-list');
  }
  // navigateToSignout(){
  //   localStorage.clear();

  //   this.router.navigate(['/login']);
  // }
  onSignout(){
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
  navigateToSignout(){
    this.showModal=true;
  }
  closeModal(){
    this.showModal=false;
  }
}
