import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import 'datatables.net';
import * as $ from 'jquery';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.css'],
})
export class ServicesListComponent {
  servicesList: any[] = [];
  showModal: boolean = false;
  success: boolean = false;
  danger: boolean = false;
  service_id: any;
  successContent: any;
  dangerContent: any;
  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private route: Router
  ) {}
  ngOnInit() {
    window.scrollTo(0, 0);
    $('.alert-success').hide();
    $('.alert-danger').hide();
    this.commonService.login();
    CommonService.ActiveClass('nav-service');
    this.http
      .post(CommonService.apiUrl + '/service-list', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if (responseData['is_successfull'] === '1') {
          this.servicesList = responseData['data'];
          this.servicesList.forEach((data) => {
            data.is_active = data.is_active === 1; // Convert is_active to boolean directly
          });
        }

        $(document).ready(function () {
          $('#services_table').DataTable({ order: [] ,"columnDefs": [
            { "orderable": false, "targets": [2,3] },
        ]});
          $('th.sorting:first').removeClass('sorting_asc');
        });
      });
    setTimeout(() => {
      $('#loading').hide();
    }, 2000);
  }
  navigateToAdd() {
    this.route.navigateByUrl('/services-add');
  }
  onRowClick(data: any) {
    let service_id: any = data.service_id;
    localStorage.setItem('service_id', service_id);
    this.route.navigateByUrl('/services-edit');
  }
  onClickLogo() {
    this.route.navigateByUrl('/dashboard');
  }
  updateactive(data: any, is_active: any) {
    console.log(data);
    is_active = !is_active;

    console.log(is_active);
    let storedArrayString: any = localStorage.getItem('userdata');
    let storedArray = JSON.parse(storedArrayString);
    let created_by = storedArray.user_id;
    const formData: FormData = new FormData();
    if (is_active === true) {
      is_active = 1;
    } else {
      is_active = 0;
    }

    formData.append('id', data.service_id);
    formData.append('name', data.service_title);
    formData.append('description', data.service_description);
    formData.append('is_active', is_active);
    formData.append('updated_by', created_by);
    formData.append('oauth_key', CommonService.oauth_key);

    // Assuming you have a service method to make the HTTP request
    this.http
      .post(CommonService.apiUrl + '/service-update', formData)
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          $('#loading').hide();

          $('.alert-success').show();
          $('.alert-danger').hide();
          $('.alert-success').html(response['success_message']);
          window.scrollTo(0,0);

          setTimeout(() => {
           window.location.reload();
          }, 1000);
        } else {
          $('#loading').hide();

          $('.alert-success').hide();
          $('.alert-danger').show();
          $('.alert-danger').html(response['error_message']);
          window.scrollTo(0,0);

        }
      });
  }
  ondelete(id: any) {
    this.showModal = true;
    this.success = false;
    this.danger = false;
    this.service_id = id;
  }
  confirmDelete() {
    $('#loading').show();

    let formData = new FormData();
    formData.append('id', this.service_id);
    formData.append('oauth_key', CommonService.oauth_key);

    this.http
      .post(CommonService.apiUrl + '/service-delete', formData)
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          $('#loading').hide();

          this.success = true;
          this.danger = false;
          this.successContent = response['success_message'];
          window.scrollTo(0, 0);
          setTimeout(() => {
           window.location.reload();
          }, 1000);
        } else {
          $('#loading').hide();

          this.success = false;
          this.danger = true;
          this.dangerContent = response['error_message'];

          // $('.alert-danger').html(response['error_message']);
          window.scrollTo(0, 0);
        }
      });
  }

  closeModal() {
    this.showModal = false;
  }
}
