import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  heading: any;
  email: any;
  showModal: boolean = false;
  constructor(
    private commonService: CommonService,
    private route: Router,
    private http: HttpClient
  ) {}
  ngOnInit() {
    this.commonService.login();
    this.heading = CommonService.main_title;
    let userdata = localStorage.getItem('userdata');
    if (userdata != null) {
      let user = JSON.parse(userdata);
      this.email = user.email;
    }
  }
  navigateToHome() {
    this.route.navigateByUrl('/dashboard');
  }

  onSignout() {
    localStorage.clear();
    this.route.navigateByUrl('/login');
  }
  Signout() {
    this.showModal = true;
  }
  closeModal() {
    this.showModal = false;
  }
  onlist() {
    if (this.heading === "Feedback") {
      this.route.navigateByUrl('/feedback-list');
    }
    if (this.heading === "Dashboard") {
      this.route.navigateByUrl('/dashboard');
    }
    if (this.heading === "Service") {
      this.route.navigateByUrl('/services-list');
    }
    if (this.heading === "Feature") {
      this.route.navigateByUrl('/feature-list');
    }
    if (this.heading === "Industry") {
      this.route.navigateByUrl('/industry-list');
    }
    if (this.heading === "Contact") {
      this.route.navigateByUrl('/contact-list');
    }
    if (this.heading === "Request for Demo") {
      this.route.navigateByUrl('/demo-list');
    }
    if (this.heading === "User") {
      this.route.navigateByUrl('/user-list');
    }
    if (this.heading === "Blog") {
      this.route.navigateByUrl('/blog-list');
    }
  }
}
