import { Component, ViewChild } from '@angular/core';
import { CommonService } from '../common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent {


  name: any;
  email:any;
  password:any;
  is_active: any;
  created_by: any;
  user_role_id:any;
  roleList:any[]=[];

  name_error: any;
  email_error: any;
  password_error: any;
  user_role_id_error: any;

  name_error_show: boolean = false;
  email_error_show:  boolean = false;
  password_error_show:  boolean = false;
  user_role_id_error_show:  boolean = false;
  showPassword: boolean = false;

  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private route: Router
  ) {}

  ngOnInit() {
    
    this.commonService.login();
    $('.alert-success').hide();
    $('.alert-danger').hide();
    let storedArrayString: any = localStorage.getItem('userdata');
    let storedArray = JSON.parse(storedArrayString);
    $('#loading').show();

    this.created_by = storedArray.user_id;
    CommonService.ActiveClass('nav-user');
    this.http
    .post(CommonService.apiUrl + '/user-role-list', {oauth_key:CommonService.oauth_key})
    .subscribe((response: any) => {
      if (response['is_successfull'] === '1') {
     this.roleList=response['data'];
     $('#loading').hide();

      } else {
        $('#loading').hide();

        $('.alert-success').hide();
        $('.alert-danger').show();
        $('.alert-danger').html(response['error_message']);
        window.scrollTo(0,0);

      }
    });
    setTimeout(() => {
      $('#loading').hide();
    },2000);
  }
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  navigateToSubmit(): void {
    if (this.validation() === true) {
      $('#loading').show();

      let is_active: any;
      if (this.is_active === true) {
        is_active = 1;
      } else {
        is_active = 0;
      }
      const formData: FormData = new FormData();
      formData.append('name', this.name);
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('created_by', this.created_by);
      formData.append('is_active', is_active);
      formData.append('user_role_id', this.user_role_id);
      formData.append('oauth_key', CommonService.oauth_key );


      // Assuming you have a service method to make the HTTP request
      this.http
        .post(CommonService.apiUrl + '/user-add', formData)
        .subscribe((response: any) => {
          if (response['is_successfull'] === '1') {
            $('#loading').hide();

            $('.alert-success').show();
            $('.alert-danger').hide();
            $('.alert-success').html(response['success_message']);
            window.scrollTo(0,0);

            setTimeout(() => {
              this.route.navigateByUrl('/user-list');
            }, 1000);
          } else {
            $('#loading').hide();

            $('.alert-success').hide();
            $('.alert-danger').show();
            $('.alert-danger').html(response['error_message']);
            window.scrollTo(0,0);

          }
        });
    } else {
      return;
    }
  }
  validation() {
    let error_message = '';
    this.name_error_show = false;
    this.email_error_show= false;
    this.password_error_show = false;
    this.user_role_id_error_show = false;
    if (this.name === null || this.name === undefined || this.name === '' || this.name == " " )  {
      error_message = 'Please enter Name.</br>';
      this.name_error_show = true;
      this.name_error='Please enter Name.';
    }
    else{
      if(this.name.trim().length < 2){
        error_message = 'Please enter a valid Name with at least 2 characters</br>';
        this.name_error_show = true;
        this.name_error = 'Please enter a valid Name with at least 2 characters';
      }

    }
    if (this.email === null || this.email === undefined || this.email === '' || this.email == " ") {
      error_message += 'Please enter Email.</br>';
      this.email_error_show = true;
      this.email_error='Please enter Email.';
      
    }else {
      // Check for proper email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        error_message += 'Please enter a valid Email.</br>';
        this.email_error_show = true;
        this.email_error = 'Please enter a valid Email.';
      }
    }
    if (this.password === null || this.password === undefined || this.password === '') {
      error_message += 'Please enter Password.</br>';
      this.password_error_show = true;
      this.password_error='Please enter Password.';
    }
    if (this.user_role_id === null || this.user_role_id === undefined || this.user_role_id === '') {
      error_message += 'Please select user role.</br>';
      this.user_role_id_error_show = true;
      this.user_role_id_error='Please select user role.';
    }
   
   
    if (error_message != '') {
      // $('.alert-success').hide();
      // $('.alert-danger').show();
      // $('.alert-danger').html(error_message);
      window.scrollTo(0,0);

      return false;
    } else {
      return true;
    }
  }
  onCancel(){
    this.route.navigateByUrl('/user-list');
  }
  onClickLogo(){
    this.route.navigateByUrl('/dashboard');
  }
}
