<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <title>Smart On Site</title>
    <!-- Favicon -->
  </head>
  <body>
    <!-- loader Start -->
    <div id="loading">
      <div id="loading-center">
        <div class="loader">
          <div class="cube">
            <div class="sides">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
              <div class="front"></div>
              <div class="back"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loader END -->
    <!-- Wrapper Start -->
    <div class="wrapper">
      <!-- Sidebar  -->
      <div class="iq-sidebar">
        <div class="iq-sidebar-logo d-flex justify-content-between">
          <a href="index.html">
            <img src="../../assets/images/logo.png" class="img-fluid" alt="" />
            <span>Smart On Site</span>
          </a>
          <div class="iq-menu-bt align-self-center">
            <div class="wrapper-menu">
              <div class="line-menu half start"></div>
              <div class="line-menu"></div>
              <div class="line-menu half end"></div>
            </div>
          </div>
        </div>
        <app-sidebar></app-sidebar>
      </div>
      <app-header></app-header>

      <div id="content-page" class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="iq-card">
                <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h4 class="card-title">Contact Details</h4>
                  </div>
                </div>
                <div class="iq-card-body">
                  <form>
                    <div class="alert alert-success" role="alert"></div>
                    <div class="alert alert-danger" role="alert"></div>
                    <div class="form-group">
                      <label for="cpass">Company Name:</label>
                      <input
                        type="text"
                        class="form-control"
                        [disabled]="disable"

                        [(ngModel)]="name"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="form-group">
                      <label for="cpass">Email:</label>
                      <input
                        type="text"
                        class="form-control"
                        [disabled]="disable"

                        [(ngModel)]="email"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="form-group">
                      <label for="cpass">Moblie Number:</label>
                      <input
                        type="text"
                        class="form-control"
                     
                        [(ngModel)]="moblie_number"
                        [ngModelOptions]="{ standalone: true }" 
                        [disabled]="disable"

                      />
                    </div>
                    <div class="form-group">
                      <label for="cpass">Message:</label>
                      <input
                        type="text"
                        class="form-control"
                     
                        [(ngModel)]="message"
                        [ngModelOptions]="{ standalone: true }"
                        [disabled]="disable"
                      />
                    </div>
                    <div class="form-group">
                      <label for="cpass">Subject:</label>
                      <input
                        type="text"
                        class="form-control"
                     
                        [(ngModel)]="subject"
                        [ngModelOptions]="{ standalone: true }"
                        [disabled]="disable"
                      />
                    </div>
                
                 
              
                    <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button> -->
                    <!-- <button
                      type="submit"
                      class="btn iq-bg-success"
                      (click)="navigateToSubmit()"
                    >
                      Add
                    </button> -->

                    <button type="button" class="btn btn-secondary" (click)="onCancel()">
                        Cancel
                      </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </body>
</html>
