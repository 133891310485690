import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import 'datatables.net'; 
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  companyCount:any;
  contactCount:any;
  constructor(private commonService: CommonService,private route:Router,private http:HttpClient){}
ngOnInit(){
  this.commonService.login();
 this.http.post(CommonService.apiUrl+'/dashboard',{
  oauth_key:CommonService.oauth_key
 }).subscribe((response:any)=>{
  if(response['is_successfull']==='1'){
    let responsedata=response['data'];
    this.companyCount=responsedata.companyCount;
    this.contactCount=responsedata.contactCount;

  }

 })
  CommonService.ActiveClass('nav-dashboard');
  window.scrollTo(0,0);

  setTimeout(()=>{
    $('#loading').hide();
  },2000);

}
onClickLogo(){
  this.route.navigateByUrl('/dashboard');
}
}
