import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Route, Router } from '@angular/router';
import { CommonService } from '../common.service';
import * as $ from 'jquery';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  email: any;
  hidePassword: boolean = true;
  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    rememberMe: [false],
  });
  password: any;
  constructor(
    private http: HttpClient,
    private route: Router,
    private commonService: CommonService,
    private location: Location,
    private fb: FormBuilder
  ) {}

  // ngOnInit(){

  //   $('.alert-success').hide();
  //   $('.alert-danger').hide();
  // }
  ngOnInit(): void {
    // Reload the page once

    this.commonService.login();
    $('.alert-success').hide();
    $('.alert-danger').hide();
    if (!localStorage.getItem('pageReloaded')) {
      localStorage.setItem('pageReloaded', 'true');
      this.reloadPage();
    }
    setTimeout(() => {
      $('#loading').hide();
    }, 2000);
  }

  reloadPage(): void {
    this.location.replaceState(this.location.path());
    window.location.reload();
  }
  onLogin() {
    if (this.loginForm.valid) {
      $('#loading').show();

      this.http
        .post(CommonService.apiUrl + '/login', {
          email: this.loginForm.value.email,
          password: this.loginForm.value.password,
          oauth_key: CommonService.oauth_key,
        })
        .subscribe((response: any) => {
          if (response['is_successfull'] === '1') {
            $('#loading').hide();

            $('.alert-success').show();
            $('.alert-danger').hide();
            $('.alert-success').html(response['success_message']);
            let userdata = JSON.stringify(response['user']);
            localStorage.setItem('userdata', userdata);
            setTimeout(() => {
              this.route.navigateByUrl('/dashboard');
            }, 1000);
          } else {
            $('#loading').hide();

            $('.alert-success').hide();
            $('.alert-danger').show();
            $('.alert-danger').html(response['error_message']);
          }
        });
    } else {
      return;
    }
  }
  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
  validation() {
    let error_message = '';
    if (this.email === null || this.email === undefined || this.email === '' || this.email=== " ") {
      error_message = 'Please enter email address.</br>';
    }
    if (
      this.password === null ||
      this.password === undefined ||
      this.password === ''
    ) {
      error_message += 'Please enter password.</br>';
    }
    if (error_message != '') {
      $('.alert-success').hide();
      $('.alert-danger').show();
      $('.alert-danger').html(error_message);
      return false;
    } else {
      return true;
    }
  }
}
