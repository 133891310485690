<div id="sidebar-scrollbar">
  <nav class="iq-sidebar-menu">
    <ul id="iq-sidebar-toggle" class="iq-menu">
      <li class="nav-dashboard" (click)="navigateToDashboard()">
        <a class="iq-waves-effect"
          ><i class="fa fa-pie-chart" aria-hidden="true"></i
          ><span>Dashboard</span></a
        >
      </li>
      <li (click)="navigateToServices()" class="nav-service">
        <a class="iq-waves-effect"
          ><i class="fa fa-wrench" aria-hidden="true"></i>

          <span>Services</span></a
        >
      </li>
      <li (click)="navigateToFeatures()" class="nav-feature">
        <a class="iq-waves-effect"
          ><i class="fa fa-cog" aria-hidden="true"></i> <span>Features</span></a
        >
      </li>
      <li (click)="navigateToIndustry()" class="nav-industry">
        <a class="iq-waves-effect"
          ><i class="fa fa-industry" aria-hidden="true"></i>
          <span>Industries</span></a
        >
      </li>
      <li (click)="navigateToBlog()" class="nav-blog">
        <a class="iq-waves-effect"
          ><i class="fa fa-folder-open" aria-hidden="true"></i>
          <span>Blog</span></a
        >
      </li>
      <li (click)="navigateToFeedback()" class="nav-feedback">
        <a class="iq-waves-effect"
          ><i class="fa fa-comments-o" aria-hidden="true"></i>
          <span>Feedback</span></a
        >
      </li>
      <li (click)="navigateToContact()" class="nav-contact">
        <a class="iq-waves-effect"
          ><i class="fa fa-address-book" aria-hidden="true"></i>
          <span>Contact</span></a
        >
      </li>
      <li (click)="navigateToRequest()" class="nav-demo">
        <a class="iq-waves-effect"
          ><i class="fa fa-bar-chart" aria-hidden="true"></i>
          <span>Request for Demo</span></a
        >
      </li>
      <li (click)="navigateToUser()" class="nav-user" *ngIf="userModal===true">
        <a class="iq-waves-effect"
          ><i class="fa-solid fa-users"></i><span>Users</span></a
        >
      </li>
      <li (click)="navigateToSignout()" class="nav-signout">
        <a class="iq-waves-effect"
          ><i class="fa fa-sign-out" aria-hidden="true"></i>
          <span>Signout</span></a
        >
      </li>
    </ul>
  </nav>
  <div class="p-3"></div>
</div>
<div class="modal" role="dialog" *ngIf="showModal === true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirm Signout</h5>
        <button
          type="button"
          class="close"
          (click)="closeModal()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <!-- <div class="alert alert-success" role="alert" *ngIf="success === true" [innerHTML]="successContent"></div>
          <div class="alert alert-danger" role="alert" *ngIf="danger === true" [innerHTML]="dangerContent"></div> -->
          Are you sure that you want to Sign Out?
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeModal()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="onSignout()"
        >
          Signout
        </button>
      </div>
    </div>
  </div>
</div>