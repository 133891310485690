<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <title>Smart On Site</title>
    <!-- Favicon -->
  </head>
  <body>
    <!-- loader Start -->
    <div id="loading">
      <div id="loading-center">
        <div class="loader">
          <div class="cube">
            <div class="sides">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
              <div class="front"></div>
              <div class="back"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loader END -->
    <!-- Wrapper Start -->
    <div class="wrapper">
      <!-- Sidebar  -->
      <div class="iq-sidebar">
        <div class="iq-sidebar-logo d-flex justify-content-between">
          <a (click)="onClickLogo()">
            <img src="../../assets/images/logo.png" class="img-fluid" alt="" />
            <span>Smart On Site</span>
          </a>
          <div class="iq-menu-bt align-self-center">
            <div class="wrapper-menu">
              <div class="line-menu half start"></div>
              <div class="line-menu"></div>
              <div class="line-menu half end"></div>
            </div>
          </div>
        </div>
        <app-sidebar></app-sidebar>
      </div>
      <app-header></app-header>

      <div id="content-page" class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="iq-card">
                <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h4 class="card-title">User List</h4>
                  </div>
                  <button
                    type="submit"
                    class="btn iq-bg-success"
                    title="Add User"
                    (click)="navigateToAdd()"
                  >
                    Add User
                  </button>
                </div>
                <div class="iq-card-body">
                  <div class="table-responsive">
                    <div class="alert alert-success" role="alert"></div>
                    <div class="alert alert-danger" role="alert"></div>
                    <table
                      id="user_table"
                      class="table table-striped table-bordered"
                    >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Name</th>
                          <th>Role</th>
                          <th>Email</th>
                          <th>Active</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <!-- <tbody>
                        <tr *ngFor="let data of userList" (click)="onRowClick(data)">
                          <td>{{ data.user_id }}</td>
                          <td>{{ data.name }}</td>
                          <td>{{ data.user_role_name }}</td>
                          <td>{{ data.email }}</td>
                          <td>{{ data.is_active }}</td>
                        </tr>
                      </tbody> -->
                      <tbody>
                        <tr *ngFor="let data of userList">
                          <td>{{ data.index }}</td>
                          <td>{{ data.name }}</td>
                          <td>{{ data.user_role_name }}</td>
                          <td>{{ data.email }}</td>
                          <td class="td_width">
                            <label class="switch">
                              <input
                                type="checkbox"
                                [(ngModel)]="data.is_active"
                              />
                              <span
                                class="slider round"
                                (click)="updateactive(data, data.is_active)"
                              ></span>
                            </label>
                          </td>
                          <td class="td_width">
                            <div class="div_td"> 
                            <span class="table-remove"
                              ><button
                                (click)="onRowClick(data)"
                                type="button"
                                class="btn iq-bg-success btn-rounded btn-sm my-0"
                                title="Edit"

                              >
                                <i class="fa-solid fa-pen"></i></button
                            ></span>
                         
                            <span class="table-remove"
                              ><button
                                (click)="ondelete(data.user_id)"
                                type="button"
                                class="btn iq-bg-danger btn-rounded btn-sm my-0"
                                title="Delete"
                                
                              >
                                <i
                                  class="fas fa-trash-alt"
                                  aria-hidden="true"
                                ></i></button
                            ></span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="modal" role="dialog" *ngIf="showModal === true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Confirm Delete</h5>
                            <button
                              type="button"
                              class="close"
                              (click)="closeModal()"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                              <div class="alert alert-success" role="alert" *ngIf="success === true" [innerHTML]="successContent"></div>
                              <div class="alert alert-danger" role="alert" *ngIf="danger === true" [innerHTML]="dangerContent"></div>
                            Are you sure you want to delete this data?
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              (click)="closeModal()"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              (click)="confirmDelete()"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </body>
</html>
