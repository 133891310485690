// ckeditor-maxlength.directive.ts
import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCkeditorMaxlength]'
})
export class CkeditorMaxlengthDirective {
  @Input() maxWords: number = 10; // Set your desired maximum word count

  constructor(private el: ElementRef) {}

  @HostListener('input') onInput() {
    const value = this.el.nativeElement.innerText;
    const words = value.split(/\s+/).filter((word: any) => word !== ''); // Split by spaces and remove empty words
    if (words.length > this.maxWords) {
      const truncatedWords = words.slice(0, this.maxWords);
      this.el.nativeElement.innerText = truncatedWords.join(' ');
    }
  }
}
