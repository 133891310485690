import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { CommonService } from '../common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
function htmlToPlainText(html: string): string {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}
@Component({
  selector: 'app-industry-add',
  templateUrl: './industry-add.component.html',
  styleUrls: ['./industry-add.component.css']
})
export class IndustryAddComponent {
  name: any;
  description: any='';

  files: File[] = [];
  is_active: any;
  created_by: any;
  name_error: any;
  description_error: any;
  files_error: any;
  
  name_error_show: boolean = false;
  description_error_show:  boolean = false;
  files_error_show:  boolean = false;
  public Editor = ClassicEditor;
  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private route: Router, private el: ElementRef
  ) {}
  @ViewChild('fileInputIndustry') fileInput: any;
  @HostListener('input', ['$event']) onInput(event: any): void {
    const inputValue: string = event.target.value;
    if (inputValue && inputValue.startsWith(' ')) {
      event.target.value = inputValue.trimStart();
    }
  }
  ngOnInit() {
    
    this.commonService.login();
    $('.alert-success').hide();
    $('.alert-danger').hide();
    let storedArrayString: any = localStorage.getItem('userdata');
    let storedArray = JSON.parse(storedArrayString);

    this.created_by = storedArray.user_id;
    CommonService.ActiveClass('nav-industry');

    setTimeout(() => {
      $('#loading').hide();
    },2000);
  }
  onFileChange(event: any) {
    this.files_error_show = false;

    this.files = []; // Assuming files is an array property in your component
    let error_message = '';
    const fileList: FileList = event.target.files;

    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        let filename: any = fileList[i].name;
        const fileExtension = filename.substring(filename.lastIndexOf('.') + 1);
        if (
          fileExtension === 'jpg' ||
          fileExtension === 'jpeg' ||
          fileExtension === 'png' ||
          fileExtension === 'gif' ||
          fileExtension === 'bmp'
        ) {
          this.files.push(fileList[i]);
        } else {
          error_message =
            "Please upload 'jpg', 'jpeg', 'png', 'gif', 'bmp' images";
        }
      }
    }

    if (error_message != '') {
      this.fileInput.nativeElement.value = '';
      this.files = [];
      this.files_error = error_message;
      this.files_error_show = true;
      // $('.alert-success').hide();
      // $('.alert-danger').show();
      window.scrollTo(0,0);
      return false;
    } else {
      return true;
    }

    // Now this.files contains an array of files
  }

  navigateToSubmit(): void {
    if (this.validation() === true) {
      $('#loading').show();

      let is_active: any;
      const formData: FormData = new FormData();
      formData.append('name', this.name);
      formData.append('description', this.description);
      // if('jpg'|| 'jpeg'|| 'png'||'gif'|| 'bmp')
      // Append each file to the form data
      for (let i = 0; i < this.files.length; i++) {
        formData.append('images', this.files[i]);
      }
      if (this.is_active === true) {
        is_active = 1;
      } else {
        is_active = 0;
      }

      formData.append('is_active', is_active);
      formData.append('created_by', this.created_by);
      formData.append('oauth_key', CommonService.oauth_key );

      // Assuming you have a service method to make the HTTP request
      this.http
        .post(CommonService.apiUrl + '/industry-add', formData)
        .subscribe((response: any) => {
          if (response['is_successfull'] === '1') {
            $('.alert-success').show();
            $('.alert-danger').hide();
            $('.alert-success').html(response['success_message']);
            window.scrollTo(0,0);
            $('#loading').hide();

            setTimeout(() => {
              this.route.navigateByUrl('/industry-list');
            }, 1000);
          } else {
            $('#loading').hide();

            $('.alert-success').hide();
            $('.alert-danger').show();
            $('.alert-danger').html(response['error_message']);
            window.scrollTo(0,0);

          }
        });
    } else {
      return;
    }
  }
  validation() {
    this.name_error_show = false;
    this.description_error_show = false;
    this.files_error_show = false;
    let error_message = '';
    if (this.name === null || this.name === undefined || this.name === '' || this.name == " ") {
      error_message = 'Please enter Industry Title</br>';
      this.name_error_show = true;
      this.name_error='Please enter Industry Title';
    }else {
      if (this.name.length < 2) {
        error_message +=
          'Industry Title should be between 2 and 200 characters</br>';
        this.name_error_show = true;
        this.name_error = 'Industry Title should be between 2 and 200 characters';
      }
      if (this.name.length > 200) {
        error_message +=
          'Industry Title should be between 2 and 200 characters</br>';
        this.name_error_show = true;
        this.name_error = 'Industry Title should be between 2 and 200 characters';
      }
    }
    if (
      this.description === null ||
      this.description === undefined ||
      this.description === ''
    ) {
      error_message += 'Please enter Industry Description</br>';
      this.description_error_show = true;
      this.description_error='Please enter Industry Description';
    }else {
      const plainTextDescription = htmlToPlainText(this.description);
      console.log(plainTextDescription);
      if (plainTextDescription.length < 2) {
        error_message +=
          'Industry Description should be between 2 and 50,000 characters</br>';
        this.description_error_show = true;
        this.description_error =
          'Industry Description should be between 2 and 50,000 characters';
      }
      if (plainTextDescription.length > 50000) {
        error_message +=
          'Industry Title should be between 2 and 50,000 characters</br>';
        this.description_error_show = true;
        this.description_error =
          'Industry Title should be between 2 and 50,000 characters';
      }
    }

    if (this.files.length === 0) {
      error_message += 'Please upload image</br>';
      this.files_error_show = true;
      this.files_error='Please upload image';
    }
    if (this.files.length >1) {
      error_message += 'Please upload one image only.</br>';
      this.files_error_show = true;
      this.files_error='Please upload one image only';
      this.fileInput.nativeElement.value = '';
      this.files=[];
    }
    if (error_message != '') {
      // $('.alert-success').hide();
      // $('.alert-danger').show();
      // $('.alert-danger').html(error_message);
      window.scrollTo(0,0);

      return false;
    } else {
      return true;
    }
  }
  onCancel(){
    this.route.navigateByUrl('/industry-list');
  }
  onClickLogo(){
    this.route.navigateByUrl('/dashboard');
  }
}
