import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import 'datatables.net'; 
import * as $ from 'jquery';

@Component({
  selector: 'app-demo-request-list',
  templateUrl: './demo-request-list.component.html',
  styleUrls: ['./demo-request-list.component.css']
})
export class DemoRequestListComponent {


  demoList:any[]=[];
  constructor(private commonService: CommonService,private http:HttpClient,private route:Router){}
  ngOnInit(){
    this.commonService.login();

    window.scrollTo(0,0);

    CommonService.ActiveClass('nav-demo');
    this.http.post(CommonService.apiUrl+'/demo-list',{oauth_key:CommonService.oauth_key}).subscribe((responseData:any)=>{
      if(responseData['is_successfull']==='1'){
        this.demoList =responseData['data'];
      }
     
      this.demoList.forEach((data, index) => {
        data.is_active = data.is_active === 1; // Convert is_active to boolean directly
      });
      $(document).ready( function () {
          $('#demo_table').DataTable({order:[],"columnDefs": [
            { "orderable": false, "targets": [4] },
        ]});
          $('th.sorting:first').removeClass('sorting_asc');

      } );
    })
    setTimeout(() => {
      $('#loading').hide();

     }, 2000);
  }
  onRowClick(data:any){
    let demo_id:any=data.company_id;
    localStorage.setItem('demo_id',demo_id);
    this.route.navigateByUrl('/demo-edit');
  }
  onClickLogo(){
    this.route.navigateByUrl('/dashboard');
  }
}
