import { Component, ViewChild } from '@angular/core';
import { CommonService } from '../common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.css']
})
export class ContactEditComponent {
  name: any;
  email:any;
  message:any;
  subject:any;
  contact_id: any;
  moblie_number:any;
  disable:boolean =true;
  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private route: Router
  ) {}
  onClickLogo(){
    this.route.navigateByUrl('/dashboard');
  }
  ngOnInit() {
    this.commonService.login();

    $('.alert-success').hide();
    $('.alert-danger').hide();
    let contact_id: any = localStorage.getItem('contact_id');

    this.contact_id = contact_id;
    CommonService.ActiveClass('nav-contact');
    const formData =new FormData();
    formData.append('id',this.contact_id);
    formData.append('oauth_key', CommonService.oauth_key );

    this.http
    .post(CommonService.apiUrl + '/contact-edit', formData)
    .subscribe((response: any) => {
      if (response['is_successfull'] === '1') {
        let responseData: any = response['data'][0];
        this.name = responseData.name;
        this.moblie_number = responseData.phone_number;
        this.email = responseData.email;
        this.message = responseData.message;
        this.subject = responseData.subject;
        this.disable=true;
      
      } else {
        $('.alert-success').hide();
        $('.alert-danger').show();
        $('.alert-danger').html(response['error_message']);
        window.scrollTo(0, 0);
      }
    });
    setTimeout(() => {
      $('#loading').hide();
    },2000);
  }

  onCancel(){
    this.route.navigateByUrl('/contact-list');
  }
}
