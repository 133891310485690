import { Component, ViewChild } from '@angular/core';
import { CommonService } from '../common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'app-demo-request',
  templateUrl: './demo-request.component.html',
  styleUrls: ['./demo-request.component.css']
})
export class DemoRequestComponent {
  name: any;
  email:any;
  address:any;
  company_id: any;
  moblie_number:any;
  disable:boolean =true;
  country_code_list:any[]=[];
  country_code_id:any;
  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private route: Router
  ) {}
  onClickLogo(){
    this.route.navigateByUrl('/dashboard');
  }
  ngOnInit() {
    this.commonService.login();

    $('.alert-success').hide();
    $('.alert-danger').hide();
    let company_id: any = localStorage.getItem('demo_id');

    this.company_id = company_id;
    CommonService.ActiveClass('nav-demo');
    const formData =new FormData();
    formData.append('id',this.company_id);
    formData.append('oauth_key', CommonService.oauth_key );
    this.http
    .post(CommonService.apiUrl + '/country-code', {
      oauth_key: CommonService.oauth_key,
    })
    .subscribe((response: any) => {
      if (response['is_successfull'] === '1') {
        this.country_code_list = response['data'];
      
      }
    });
    this.http
    .post(CommonService.apiUrl + '/demo-edit', formData)
    .subscribe((response: any) => {
      if (response['is_successfull'] === '1') {
        let responseData: any = response['data'][0];
        this.name = responseData.company_name;
        this.moblie_number = responseData.phone_number;
        this.email = responseData.email;
        this.address = responseData.address;
        this.country_code_id = responseData.country_code_id;
        this.disable=true;
      
      } else {
        $('.alert-success').hide();
        $('.alert-danger').show();
        $('.alert-danger').html(response['error_message']);
        window.scrollTo(0, 0);
      }
    });
    setTimeout(() => {
      $('#loading').hide();
    },2000);
  }

  onCancel(){
    this.route.navigateByUrl('/demo-list');
  }
}
