<!doctype html>
<html lang="en">
   
<!-- Mirrored from templates.iqonic.design/sofbox-admin/sofbox-dashboard-html/html/dashboard1.html by HTTrack Website Copier/3.x [XR&CO'2014], Wed, 04 Aug 2021 05:55:36 GMT -->
<head>
      <!-- Required meta tags -->
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
      <title>Smart On Site</title>
      <!-- Favicon -->

   </head>
   <body>
      <!-- loader Start -->
      <div id="loading">
         <div id="loading-center">
            <div class="loader">
               <div class="cube">
                  <div class="sides">
                     <div class="top"></div>
                     <div class="right"></div>
                     <div class="bottom"></div>
                     <div class="left"></div>
                     <div class="front"></div>
                     <div class="back"></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- loader END -->
      <!-- Wrapper Start -->
      <div class="wrapper">
      <!-- Sidebar  -->
      <div class="iq-sidebar">
            <div class="iq-sidebar-logo d-flex justify-content-between">
               <a (click)="onClickLogo()">
               <img src="../../assets/images/logo.png" class="img-fluid" alt="">
               <span>Smart On Site</span>
               </a>
               <div class="iq-menu-bt align-self-center">
                  <div class="wrapper-menu">
                     <div class="line-menu half start"></div>
                     <div class="line-menu"></div>
                     <div class="line-menu half end"></div>
                  </div>
               </div>
            </div>
           <app-sidebar></app-sidebar>
         </div>
      <!-- TOP Nav Bar -->
     <app-header></app-header>
      <!-- TOP Nav Bar END -->
      <!-- Page Content  -->
      <!-- Right Sidebar Panel Start-->
      <!-- <div class="right-sidebar-mini">
         <div class="right-sidebar-toggle">
            <i class="ri-arrow-left-line side-left-icon"></i>
            <i class="ri-arrow-right-line side-right-icon"></i>
         </div>
         <div class="right-sidebar-panel p-0">

                  <div class="iq-card shadow-none">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h6 class="card-title">Active Users</h6>
                        </div>
                        <div class="iq-card-header-toolbar d-flex align-items-center">
                           <div class="dropdown">
                              <span class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" >
                              <i class="ri-more-2-fill"></i>
                              </span>
                              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                 <a class="dropdown-item" href="#">Action</a>
                                 <a class="dropdown-item" href="#">Another action</a>
                                 <a class="dropdown-item" href="#">Something else here</a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="iq-card-body">
                        <div class="media align-items-center mb-4">
                           <div class="iq-profile-avatar status-online">
                              <img class="rounded-circle avatar-60" src="../../assets/images/user/01.jpg" alt="">
                           </div>
                           <div class="media-body ml-3">
                              <h6 class="mb-0"><a href="#">Anna Sthesia</a></h6>
                              <p class="mb-0">Admin</p>
                           </div>
                        </div>
                        <div class="media align-items-center mb-4">
                           <div class="iq-profile-avatar status-online">
                              <img class="rounded-circle avatar-60" src="../../assets/images/user/02.jpg" alt="">
                           </div>
                           <div class="media-body ml-3">
                              <h6 class="mb-0"><a href="#">Paul Molive</a></h6>
                              <p class="mb-0">Admin</p>
                           </div>
                        </div>
                        <div class="media align-items-center mb-4">
                           <div class="iq-profile-avatar status-online">
                              <img class="rounded-circle avatar-60" src="../../assets/images/user/03.jpg" alt="">
                           </div>
                           <div class="media-body ml-3">
                              <h6 class="mb-0"><a href="#">Anna Mull</a></h6>
                              <p class="mb-0">Admin</p>
                           </div>
                        </div>
                        <div class="media align-items-center mb-4">
                           <div class="iq-profile-avatar status-online">
                              <img class="rounded-circle avatar-60" src="../../assets/images/user/04.jpg" alt="">
                           </div>
                           <div class="media-body ml-3">
                              <h6 class="mb-0"><a href="#">Paige Turner</a></h6>
                              <p class="mb-0">Admin</p>
                           </div>
                        </div>
                        <div class="media align-items-center mb-4">
                           <div class="iq-profile-avatar status-online">
                              <img class="rounded-circle avatar-60" src="../../assets/images/user/01.jpg" alt="">
                           </div>
                           <div class="media-body ml-3">
                              <h6 class="mb-0"><a href="#">Bob Frapples</a></h6>
                              <p class="mb-0">Admin</p>
                           </div>
                        </div>
                        <div class="media align-items-center mb-4">
                           <div class="iq-profile-avatar status-online">
                              <img class="rounded-circle avatar-60" src="../../assets/images/user/02.jpg" alt="">
                           </div>
                           <div class="media-body ml-3">
                              <h6 class="mb-0"><a href="#">Barb Ackue</a></h6>
                              <p class="mb-0">Admin</p>
                           </div>
                        </div>
                        <div class="media align-items-center mb-4">
                           <div class="iq-profile-avatar status-online">
                              <img class="rounded-circle avatar-60" src="../../assets/images/user/03.jpg" alt="">
                           </div>
                           <div class="media-body ml-3">
                              <h6 class="mb-0"><a href="#">Greta Life</a></h6>
                              <p class="mb-0">Admin</p>
                           </div>
                        </div>
                        <div class="media align-items-center mb-4">
                           <div class="iq-profile-avatar status-away">
                              <img class="rounded-circle avatar-60" src="../../assets/images/user/04.jpg" alt="">
                           </div>
                           <div class="media-body ml-3">
                              <h6 class="mb-0"><a href="#">Ira Membrit</a></h6>
                              <p class="mb-0">Admin</p>
                           </div>
                        </div>
                        <div class="media align-items-center mb-4">
                           <div class="iq-profile-avatar status-away">
                              <img class="rounded-circle avatar-60" src="../../assets/images/user/01.jpg" alt="">
                           </div>
                           <div class="media-body ml-3">
                              <h6 class="mb-0"><a href="#">Pete Sariya</a></h6>
                              <p class="mb-0">Admin</p>
                           </div>
                        </div>
                        <div class="media align-items-center">
                           <div class="iq-profile-avatar">
                              <img class="rounded-circle avatar-60" src="../../assets/images/user/02.jpg" alt="">
                           </div>
                           <div class="media-body ml-3">
                              <h6 class="mb-0"><a href="#">Monty Carlo</a></h6>
                              <p class="mb-0">Admin</p>
                           </div>
                        </div>
                     </div>
                  </div>

         </div>
      </div> -->
      <!-- Right Sidebar Panel End-->
      <div id="content-page" class="content-page">
         <div class="container-fluid map-main-content">
            <div class="row">
               <div class="col-lg-4">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-body">
                        <div class="row align-items-center">
                           <div class="col-lg-6 text-center">
                              <div class="rounded-circle iq-card-icon iq-bg-success"><i class="ri-group-line"></i></div>
                           </div>
                           <div class="col-lg-6">
                              <h3 class="mb-0">Contacts<small class="d-block font-size-16 text-secondary">Total {{contactCount}}</small></h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-body">
                        <div class="row align-items-center">
                           <div class="col-lg-6 text-center">
                              <div class="rounded-circle iq-card-icon iq-bg-warning"><i class="ri-bar-chart-grouped-line"></i></div>                           </div>
                           <div class="col-lg-6">
                              <h3 class="mb-0">Request for Demo<small class="d-block font-size-16 text-secondary">Total {{companyCount}}</small></h3>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
        
            </div>
       
         </div>
      </div>
   </div>
      <!-- Wrapper END -->
      <!-- Footer -->
      <app-footer></app-footer>
 
      <!-- Footer END -->
      <!-- Optional JavaScript -->
      <!-- jQuery first, then Popper.js, then Bootstrap JS -->
     
   </body>

<!-- Mirrored from templates.iqonic.design/sofbox-admin/sofbox-dashboard-html/html/dashboard1.html by HTTrack Website Copier/3.x [XR&CO'2014], Wed, 04 Aug 2021 05:55:36 GMT -->
</html>
