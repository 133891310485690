import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import 'datatables.net';
import * as $ from 'jquery';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
})
export class UserListComponent {
  userList: any[] = [];
  showModal: boolean = false;
  success: boolean = false;
  danger: boolean = false;
  user_id: any;
  successContent: any;
  dangerContent: any;
  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private route: Router
  ) {}
  ngOnInit() {
    window.scrollTo(0, 0);
    $('.alert-success').hide();
    $('.alert-danger').hide();
    this.commonService.login();
    CommonService.ActiveClass('nav-user');
    this.http
      .post(CommonService.apiUrl + '/user-list', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if (responseData['is_successfull'] === '1') {
          this.userList = responseData['data'];
          this.userList.forEach((data, index) => {
            data.is_active = data.is_active === 1; // Convert is_active to boolean directly
          });
        }

        $(document).ready(function () {
          $('#user_table').DataTable({ order: [] });
          $('th.sorting:first').removeClass('sorting_asc');
        });
      });
    setTimeout(() => {
      $('#loading').hide();
    }, 2000);
  }
  navigateToAdd() {
    this.route.navigateByUrl('/user-add');
  }
  onRowClick(data: any) {
    let user_id: any = data.user_id;
    localStorage.setItem('user_id', user_id);
    this.route.navigateByUrl('/user-edit');
  }
  onClickLogo() {
    this.route.navigateByUrl('/dashboard');
  }
  updateactive(data: any, is_active: any) {
    console.log(data);
    is_active = !is_active;

    console.log(is_active);
    let storedArrayString: any = localStorage.getItem('userdata');
    let storedArray = JSON.parse(storedArrayString);
    let created_by = storedArray.user_id;
    const formData: FormData = new FormData();

    if (is_active === true) {
      is_active = 1;
    } else {
      is_active = 0;
    }

    formData.append('id', data.user_id);
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('created_by', data.created_by);
    formData.append('is_active', is_active);
    formData.append('user_role_id', data.user_role_id);
    formData.append('oauth_key', CommonService.oauth_key);
    // Assuming you have a user method to make the HTTP request
    this.http
      .post(CommonService.apiUrl + '/user-update', formData)
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          $('#loading').hide();

          $('.alert-success').show();
          $('.alert-danger').hide();
          $('.alert-success').html(response['success_message']);
          window.scrollTo(0, 0);

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          $('#loading').hide();

          $('.alert-success').hide();
          $('.alert-danger').show();
          $('.alert-danger').html(response['error_message']);
          window.scrollTo(0, 0);
        }
      });
  }
  ondelete(id: any) {
    this.showModal = true;
    this.success = false;
    this.danger = false;
    this.user_id = id;
  }
  confirmDelete() {
    $('#loading').show();

    let formData = new FormData();
    formData.append('id', this.user_id);
    formData.append('oauth_key', CommonService.oauth_key);

    this.http
      .post(CommonService.apiUrl + '/user-delete', formData)
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          $('#loading').hide();

          this.success = true;
          this.danger = false;
          this.successContent = response['success_message'];
          window.scrollTo(0, 0);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          $('#loading').hide();

          this.success = false;
          this.danger = true;
          this.dangerContent = response['error_message'];

          // $('.alert-danger').html(response['error_message']);
          window.scrollTo(0, 0);
        }
      });
  }

  closeModal() {
    this.showModal = false;
  }
}
