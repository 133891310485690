<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <title>Smart On Site</title>
    <!-- Favicon -->
  </head>
  <body>
    <!-- loader Start -->
    <div id="loading">
      <div id="loading-center">
        <div class="loader">
          <div class="cube">
            <div class="sides">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
              <div class="front"></div>
              <div class="back"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loader END -->
    <!-- Wrapper Start -->
    <div class="wrapper">
      <!-- Sidebar  -->
      <div class="iq-sidebar">
        <div class="iq-sidebar-logo d-flex justify-content-between">
          <a (click)="onClickLogo()">
            <img src="../../assets/images/logo.png" class="img-fluid" alt="" />
            <span>Smart On Site</span>
          </a>
          <div class="iq-menu-bt align-self-center">
            <div class="wrapper-menu">
              <div class="line-menu half start"></div>
              <div class="line-menu"></div>
              <div class="line-menu half end"></div>
            </div>
          </div>
        </div>
        <app-sidebar></app-sidebar>
      </div>
      <app-header></app-header>

      <div id="content-page" class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="iq-card">
                <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h4 class="card-title">Edit User</h4>
                  </div>
                </div>
                <div class="iq-card-body">
                  <form>
                    <div class="alert alert-success" role="alert"></div>
                    <div class="alert alert-danger" role="alert"></div>
                    <div class="form-group">
                      <label for="cpass">User Name:</label>
                      <input
                        [ngClass]="{ 'error-border': name_error_show }"
                        type="text"
                        class="form-control"
                        id="cpass"
                        [(ngModel)]="name"
                        [ngModelOptions]="{ standalone: true }"
                        maxlength="100"
                        
                      />
                      <div
                        class="error_message"
                        *ngIf="name_error_show === true"
                      >
                        {{ name_error }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="cpass">Email:</label>
                      <input
                        [ngClass]="{ 'error-border': email_error_show }"
                        type="text"
                        class="form-control"
                        id="cpass"
                        maxlength="100"
                        [(ngModel)]="email"
                        [ngModelOptions]="{ standalone: true }"
                      />
                      <div
                        class="error_message"
                        *ngIf="email_error_show === true"
                      >
                        {{ email_error }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="cpass">Password:</label>
                      <div class="input-group" >
                        <input
                          [ngClass]="{ 'error-border': password_error_show }"
                          [attr.type]="showPassword ? 'text' : 'password'"
                          class="form-control"
                          id="cpass"
                          maxlength="100"
                          [(ngModel)]="password"
                          [ngModelOptions]="{ standalone: true }"
                         [disabled]="changePassword===false"
                        />
                        <div class="input-group-append" *ngIf="changePassword===true">
                          <div class="input-group-append" (click)="togglePasswordVisibility()" >
                            <span class="input-group-text">
                              <i
                                class="fa"
                                [ngClass]="{
                                  'fa-eye': showPassword,
                                  'fa-eye-slash': !showPassword
                                }"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-check mt-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="changePasswordCheckbox"
                          [(ngModel)]="changePassword"
                          [ngModelOptions]="{ standalone: true }"
                          (click)="changepassword()"
                        />
                        <label class="form-check-label" for="changePasswordCheckbox">
                          Change Password
                        </label>
                      </div>
                      <div class="error_message" *ngIf="password_error_show === true">
                        {{ password_error }}
                      </div>
                    </div>
                    
                    
                    <div class="form-group">
                      <label for="cpass">User Role:</label>
                      <!-- <select class="form-select" aria-label="Default select example">
                            <option >Open this select menu</option>
                          
                          </select> -->
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        [(ngModel)]="user_role_id"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option
                          *ngFor="let role of roleList"
                          [value]="role.user_role_id"
                        >
                          {{ role.user_role_name }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="vpass">Active:</label><br />
                      <label class="switch">
                        <input
                          type="checkbox"
                          [(ngModel)]="is_active"
                          [ngModelOptions]="{ standalone: true }"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button> -->
                    <button
                      type="submit"
                      class="btn iq-bg-success"
                      (click)="navigateToSubmit()"
                    >
                      Update
                    </button>

                    <button
                      type="button"
                      class="btn iq-bg-danger"
                      (click)="onDelete()"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="onCancel()"
                    >
                      Cancel
                    </button>
                  </form>
                  <div class="modal" role="dialog" *ngIf="showModal === true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">Confirm Delete</h5>
                          <button
                            type="button"
                            class="close"
                            (click)="closeModal()"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div
                            class="alert alert-success"
                            role="alert"
                            *ngIf="success === true"
                            [innerHTML]="successContent"
                          ></div>
                          <div
                            class="alert alert-danger"
                            role="alert"
                            *ngIf="danger === true"
                            [innerHTML]="dangerContent"
                          ></div>
                          Are you sure you want to delete this data?
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            (click)="closeModal()"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger"
                            (click)="confirmDelete()"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </body>
</html>
