import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FeedbackListComponent } from './feedback-list/feedback-list.component';
import { FeedbackAddComponent } from './feedback-add/feedback-add.component';
import { FeedbackEditComponent } from './feedback-edit/feedback-edit.component';
import { IndustryAddComponent } from './industry-add/industry-add.component';
import { IndustryEditComponent } from './industry-edit/industry-edit.component';
import { IndustryListComponent } from './industry-list/industry-list.component';
import { ServicesAddComponent } from './services-add/services-add.component';
import { ServicesEditComponent } from './services-edit/services-edit.component';
import { ServicesListComponent } from './services-list/services-list.component';
import { FeaturesAddComponent } from './features-add/features-add.component';
import { FeaturesEditComponent } from './features-edit/features-edit.component';
import { FeaturesListComponent } from './features-list/features-list.component';
import { ContactEditComponent } from './contact-edit/contact-edit.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogAddComponent } from './blog-add/blog-add.component';
import { BlogEditComponent } from './blog-edit/blog-edit.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserAddComponent } from './user-add/user-add.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DemoRequestComponent } from './demo-request/demo-request.component';
import { DemoRequestListComponent } from './demo-request-list/demo-request-list.component';
import { CkeditorMaxlengthDirective } from './ckeditor-maxlength.directive';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'feedback-list', component: FeedbackListComponent },
  { path: 'feedback-add', component: FeedbackAddComponent },
  { path: 'feedback-edit', component: FeedbackEditComponent },
  { path: 'industry-list', component: IndustryListComponent },
  { path: 'industry-add', component: IndustryAddComponent },
  { path: 'industry-edit', component: IndustryEditComponent },
  { path: 'services-list', component: ServicesListComponent },
  { path: 'services-add', component: ServicesAddComponent },
  { path: 'services-edit', component: ServicesEditComponent },
  { path: 'feature-add', component: FeaturesAddComponent },
  { path: 'feature-edit', component: FeaturesEditComponent },
  { path: 'feature-list', component: FeaturesListComponent },
  { path: 'contact-edit', component: ContactEditComponent },
  { path: 'contact-list', component: ContactListComponent },
  { path: 'blog-add', component: BlogAddComponent },
  { path: 'blog-edit', component: BlogEditComponent },
  { path: 'blog-list', component: BlogListComponent },
  { path: 'user-add', component: UserAddComponent },
  { path: 'user-edit', component: UserEditComponent },
  { path: 'user-list', component: UserListComponent },
  { path: 'demo-edit', component: DemoRequestComponent },
  { path: 'demo-list', component: DemoRequestListComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    FeedbackListComponent,
    FeedbackAddComponent,
    FeedbackEditComponent,
    IndustryAddComponent,
    IndustryEditComponent,
    IndustryListComponent,
    ServicesAddComponent,
    ServicesEditComponent,
    ServicesListComponent,
    FeaturesAddComponent,
    FeaturesEditComponent,
    FeaturesListComponent,
    ContactEditComponent,
    ContactListComponent,
    BlogListComponent,
    BlogAddComponent,
    BlogEditComponent,
    UserListComponent,
    UserAddComponent,
    UserEditComponent,
    DemoRequestComponent,
    DemoRequestListComponent,
    CkeditorMaxlengthDirective,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    CKEditorModule,
    RouterModule.forRoot(appRoutes)

  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
