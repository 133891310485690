<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <title>Smart On Site</title>
    <!-- Favicon -->
  </head>
  <body>
    <!-- loader Start -->
    <div id="loading">
      <div id="loading-center">
        <div class="loader">
          <div class="cube">
            <div class="sides">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
              <div class="front"></div>
              <div class="back"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loader END -->
    <!-- Wrapper Start -->
    <div class="wrapper">
      <!-- Sidebar  -->
      <div class="iq-sidebar">
        <div class="iq-sidebar-logo d-flex justify-content-between">
          <a (click)="onClickLogo()">
            <img src="../../assets/images/logo.png" class="img-fluid" alt="" />
            <span>Smart On Site</span>
          </a>
          <div class="iq-menu-bt align-self-center">
            <div class="wrapper-menu">
              <div class="line-menu half start"></div>
              <div class="line-menu"></div>
              <div class="line-menu half end"></div>
            </div>
          </div>
        </div>
        <app-sidebar></app-sidebar>
      </div>
      <app-header></app-header>

      <div id="content-page" class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="iq-card">
                <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h4 class="card-title">Add Service</h4>
                  </div>
                </div>
                <div class="iq-card-body">
                  <form>
                    <div class="alert alert-success" role="alert"></div>
                    <div class="alert alert-danger" role="alert"></div>
                    <div class="form-group">
                      <label for="cpass">Service Title:</label>
                      <input
                        [ngClass]="{ 'error-border': name_error_show }"
                        type="text"
                        class="form-control"
                        id="cpass"
                        value=""
                        [(ngModel)]="name"
                        [ngModelOptions]="{ standalone: true }"
                      />
                      <div
                        class="error_message"
                        *ngIf="name_error_show === true"
                      >
                        {{ name_error }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="npass">Service Description: </label>

                      <div [ngClass]="{'error-border': description_error_show}">
                        <ckeditor
                          [editor]="Editor"
                          [(ngModel)]="description"
                          [ngModelOptions]="{ standalone: true }"
                        ></ckeditor>
                      </div>
                      <div
                        class="error_message"
                        *ngIf="description_error_show === true"
                      >
                        {{ description_error }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="vpass">Service Image:</label>
                      <input
                        type="file"
                        class="form-control"
                        #fileInputService
                        value=""
                        (change)="onFileChange($event)"
                        multiple
                        [ngClass]="{ 'error-border': files_error_show }"
                      />
                      <div
                        class="error_message"
                        *ngIf="files_error_show === true"
                      >
                        {{ files_error }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="vpass">Active:</label><br />
                      <label class="switch">
                        <input
                          type="checkbox"
                          [(ngModel)]="is_active"
                          [ngModelOptions]="{ standalone: true }"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button> -->
                    <button
                      type="submit"
                      class="btn iq-bg-success"
                      (click)="navigateToSubmit()"
                    >
                      Add
                    </button>

                    <button
                      type="reset"
                      class="btn iq-bg-danger"
                      (click)="onCancel()"
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </body>
</html>
