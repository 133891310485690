import { Component } from '@angular/core';
import { CommonService } from '../common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import 'datatables.net';
import * as $ from 'jquery';

@Component({
  selector: 'app-industry-list',
  templateUrl: './industry-list.component.html',
  styleUrls: ['./industry-list.component.css'],
})
export class IndustryListComponent {
  industryList: any[] = [];
  showModal: boolean = false;
  success: boolean = false;
  danger: boolean = false;
  industry_id: any;
  successContent: any;
  dangerContent: any;
  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private route: Router
  ) {}
  ngOnInit() {
    this.commonService.login();
    window.scrollTo(0, 0);
    $('.alert-success').hide();
    $('.alert-danger').hide();
    CommonService.ActiveClass('nav-industry');
    this.http
      .post(CommonService.apiUrl + '/industry-list', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if (responseData['is_successfull'] === '1') {
          this.industryList = responseData['data'];
          this.industryList.forEach((data, index) => {
            data.is_active = data.is_active === 1; // Convert is_active to boolean directly
          });
        }

        $(document).ready(function () {
          $('#industry_table').DataTable({order:[],"columnDefs": [
            { "orderable": false, "targets": [2,3] },
        ]});
          $('th.sorting:first').removeClass('sorting_asc');
        });
      });
    setTimeout(() => {
      $('#loading').hide();
    }, 2000);
  }
  navigateToAdd() {
    this.route.navigateByUrl('/industry-add');
  }
  onRowClick(data: any) {
    let industry_id: any = data.industry_id;
    localStorage.setItem('industry_id', industry_id);
    this.route.navigateByUrl('/industry-edit');
  }
  onClickLogo() {
    this.route.navigateByUrl('/dashboard');
  }
  updateactive(data: any, is_active: any) {
    console.log(data);
    is_active = !is_active;

    console.log(is_active);
    let storedArrayString: any = localStorage.getItem('userdata');
    let storedArray = JSON.parse(storedArrayString);
    let created_by = storedArray.user_id;
    const formData: FormData = new FormData();
    if (is_active === true) {
      is_active = 1;
    } else {
      is_active = 0;
    }

    formData.append('id', data.industry_id);
    formData.append('name', data.industry_title);
    formData.append('description', data.industry_description);
    formData.append('is_active', is_active);
    formData.append('updated_by', created_by);
    formData.append('oauth_key', CommonService.oauth_key);

    // Assuming you have a industry method to make the HTTP request
    this.http
      .post(CommonService.apiUrl + '/industry-update', formData)
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          $('#loading').hide();

          $('.alert-success').show();
          $('.alert-danger').hide();
          $('.alert-success').html(response['success_message']);
          window.scrollTo(0,0);

          setTimeout(() => {
            window.location.reload();

          }, 1000);
        } else {
          $('#loading').hide();

          $('.alert-success').hide();
          $('.alert-danger').show();
          $('.alert-danger').html(response['error_message']);
          window.scrollTo(0,0);

        }
      });
  }
  ondelete(id: any) {
    this.showModal = true;
    this.success = false;
    this.danger = false;
    this.industry_id = id;
  }
  confirmDelete() {
    $('#loading').show();

    let formData = new FormData();
    formData.append('id', this.industry_id);
    formData.append('oauth_key', CommonService.oauth_key);

    this.http
      .post(CommonService.apiUrl + '/industry-delete', formData)
      .subscribe((response: any) => {
        if (response['is_successfull'] === '1') {
          $('#loading').hide();

          this.success = true;
          this.danger = false;
          this.successContent = response['success_message'];
          window.scrollTo(0, 0);
          setTimeout(() => {
           window.location.reload();
          }, 1000);
        } else {
          $('#loading').hide();

          this.success = false;
          this.danger = true;
          this.dangerContent = response['error_message'];

          // $('.alert-danger').html(response['error_message']);
          window.scrollTo(0, 0);
        }
      });
  }

  closeModal() {
    this.showModal = false;
  }
}
