import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {environment} from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  // public static apiUrl = 'http://localhost:3000/api/admin';
  public static apiUrl = environment.apiUrl;
  public static oauth_key = 'wYIXdiZIJ7OLPA384f19lWdh2jALNHTME4WR0Z79UPLB40PH0abVUHFT8';
  private ctrlPressed = false;

  public static main_title:any;
  constructor(private http:HttpClient, private route:Router) {   }
 

  public static ActiveClass(navClass:any){
    $('.iq-menu li').removeClass('active');
    
    // Add activeClass to the selected li element
    $('.'+navClass).addClass('active');
    if(navClass==='nav-feedback'){
      CommonService.main_title= 'Feedback';
    }
    if(navClass==='nav-dashboard'){
      CommonService.main_title= 'Dashboard';
    }
    if(navClass==='nav-service'){
      CommonService.main_title= 'Service';
    }
    if(navClass==='nav-feature'){
      CommonService.main_title= 'Feature';
    }
    if(navClass==='nav-industry'){
      CommonService.main_title= 'Industry';
    }
    if(navClass==='nav-contact'){
      CommonService.main_title= 'Contact';
    }
    if(navClass==='nav-demo'){
      CommonService.main_title= 'Request for Demo';
    }
    if(navClass==='nav-user'){
      CommonService.main_title= 'User';
    }
    if(navClass==='nav-blog'){
      CommonService.main_title= 'Blog';
    }

  }
   login() {
    let userdata=localStorage.getItem('userdata');
    if(userdata===null||userdata===''||userdata===undefined){
      this.route.navigateByUrl('/login');
    }
  }

}
