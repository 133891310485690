<!DOCTYPE html>
<html lang="en">
  <!-- Mirrored from templates.iqonic.design/sofbox-admin/sofbox-dashboard-html/html/sign-in.html by HTTrack Website Copier/3.x [XR&CO'2014], Wed, 04 Aug 2021 05:56:17 GMT -->
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <title>Smart On Site</title>
    <!-- Favicon -->
    <script src="assets/js/owl.carousel.min.js"></script>
  </head>
  <body>
    <!-- loader Start -->
    <div id="loading" class="loading">
      <div id="loading-center">
        <div class="loader">
          <div class="cube">
            <div class="sides">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
              <div class="front"></div>
              <div class="back"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loader END -->
    <!-- Sign in Start -->
    <section class="sign-in-page bg-white">
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-sm-6 align-self-center">
            <div class="sign-in-from">
              <h1 class="mb-0">Sign in</h1>
              <p>
                Enter your email address and password to access admin panel.
              </p>

              <form [formGroup]="loginForm" class="mt-4" (ngSubmit)="onLogin()">
                <div class="alert alert-success" role="alert">
                  <!-- Success message if needed -->
                </div>
                <div class="alert alert-danger" role="alert">
                  <!-- Success message if needed -->
                </div>

                <div
                  class="alert alert-danger"
                  role="alert"
                  *ngIf="loginForm.errors && loginForm.dirty"
                >
                  Please fix the form errors.
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    class="form-control mb-0"
                    id="exampleInputEmail1"
                    placeholder="Enter email"
                    formControlName="email"
                  />
                  <div
                    class="error_code"
                    *ngIf="
                      loginForm.controls.email.invalid &&
                      (loginForm.controls.email.dirty ||
                        loginForm.controls.email.touched)
                    "
                  >
                    <div
                      class="error_code"
                      *ngIf="loginForm.controls.email.hasError('required')"
                    >
                      Please enter email address.
                    </div>
                    <div
                      class="error_code"
                      *ngIf="loginForm.controls.email.hasError('email')"
                    >
                      Please enter valid email format.
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <div class="input-group">
                    <input
                      type="{{ hidePassword ? 'password' : 'text' }}"
                      class="form-control mb-0"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      formControlName="password"
                    />
                    <div
                      class="input-group-append"
                      (click)="togglePasswordVisibility()"
                    >
                      <span class="input-group-text">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye': !hidePassword,
                            'fa-eye-slash': hidePassword
                          }"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div
                    class="error_code"
                    *ngIf="
                      loginForm.controls.password.invalid &&
                      (loginForm.controls.password.dirty ||
                        loginForm.controls.password.touched)
                    "
                  >
                    <div
                      class="error_code"
                      *ngIf="loginForm.controls.password.hasError('required')"
                    >
                      Please enter the password.
                    </div>
                  </div>
                </div>

                <div class="d-inline-block w-100">
                  <button
                    type="submit"
                    class="btn btn-primary float-right"
                    [disabled]="loginForm.invalid"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-sm-6 text-center">
            <div
              class="sign-in-detail text-white"
              style="
                background: url(../../assets/images/login/2.jpg) no-repeat 0 0;
                background-size: cover;
              "
            >
              <!-- <a class="sign-in-logo mb-5" href="#"
                ><img
                  src="../../assets/images/logo-white.png"
                  class="img-fluid"
                  alt="logo"
              /></a> -->
              <!-- <a class="sign-in-logo mb-5" href="#"
                ><img
                  src="../../assets/images/logo.png"
                  class="img-fluid"
                  alt="logo"
              /></a> -->
              <div
                
              >
                <div class="item">
                  <img
                    src="../../assets/images/1.png"
                    class="img-fluid mb-4"
                    alt="logo"
                    style="margin-top:-2%"
                  />
                  
                </div>
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Sign in END -->
    <!-- Optional JavaScript -->
    <!-- jQuery first, then Popper.js, then Bootstrap JS -->
  </body>

  <!-- Mirrored from templates.iqonic.design/sofbox-admin/sofbox-dashboard-html/html/sign-in.html by HTTrack Website Copier/3.x [XR&CO'2014], Wed, 04 Aug 2021 05:56:18 GMT -->
</html>
