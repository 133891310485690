<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <title>Smart On Site</title>
    <!-- Favicon -->
  </head>
  <body>
    <!-- loader Start -->
    <div id="loading">
      <div id="loading-center">
        <div class="loader">
          <div class="cube">
            <div class="sides">
              <div class="top"></div>
              <div class="right"></div>
              <div class="bottom"></div>
              <div class="left"></div>
              <div class="front"></div>
              <div class="back"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loader END -->
    <!-- Wrapper Start -->
    <div class="wrapper">
      <!-- Sidebar  -->
      <div class="iq-sidebar">
        <div class="iq-sidebar-logo d-flex justify-content-between">
          <a (click)="onClickLogo()">
            <img src="../../assets/images/logo.png" class="img-fluid" alt="" />
            <span>Smart On Site</span>
          </a>
          <div class="iq-menu-bt align-self-center">
            <div class="wrapper-menu">
              <div class="line-menu half start"></div>
              <div class="line-menu"></div>
              <div class="line-menu half end"></div>
            </div>
          </div>
        </div>
        <app-sidebar></app-sidebar>
      </div>
      <app-header></app-header>

      <div id="content-page" class="content-page">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="iq-card">
                <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h4 class="card-title">Request for Demo Details</h4>
                  </div>
                </div>
                <div class="iq-card-body">
                  <form>
                    <div class="alert alert-success" role="alert"></div>
                    <div class="alert alert-danger" role="alert"></div>
                    <div class="form-group">
                      <label for="cpass">Company Name:</label>
                      <input
                        type="text"
                        class="form-control"
                        [disabled]="disable"
                        [(ngModel)]="name"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="form-group">
                      <label for="cpass">Email:</label>
                      <input
                        type="text"
                        class="form-control"
                        [disabled]="disable"
                        [(ngModel)]="email"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="form-group">
                      <label for="cpass">Moblie Number:</label>
                      <div class="row">
                        <div class="col-md-12 col-12 dropdown-class">
                          <select
                            class="col-2 col-md-2 form-control"
                            [disabled]="disable"
                            [(ngModel)]="country_code_id"
                            [ngModelOptions]="{ standalone: true }"
                          >
                            <option
                              *ngFor="let data of country_code_list"
                              [value]="data.country_code_id"
                            >
                              {{ data.country_code }}
                            </option>
                          </select>
                          <input
                            type="text"
                            class="form-control col-10 col-md-10"
                            [(ngModel)]="moblie_number"
                            [ngModelOptions]="{ standalone: true }"
                            [disabled]="disable"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="cpass">Address:</label>
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="address"
                        [ngModelOptions]="{ standalone: true }"
                        [disabled]="disable"
                      />
                    </div>

                    <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button> -->
                    <!-- <button
                      type="submit"
                      class="btn iq-bg-success"
                      (click)="navigateToSubmit()"
                    >
                      Add
                    </button> -->

                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="onCancel()"
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </body>
</html>
